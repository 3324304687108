import { lory } from 'lory.js';
import { areNodesPresent, toArray } from './utils.js';
import { carousel } from './carousel-helpers.js';

const THUMB_CLASSNAME = 'c-thumbnail-carousel__thumb';
const ACT_CLASSNAME   = 'is-active';
const KEYCODE_LEFT = 37;
const KEYCODE_RIGHT = 39;
const SLIDE_SPEED = 700;


class ThumbnailCarousel {
  constructor() {
    const nodelist = this.getNodes();
    if (areNodesPresent(nodelist)) {
      this.DOM = nodelist;
      this.initLory();
    }
  }

  getNodes() {
    const carousel = document.querySelector('.js-thumbnail-carousel');
    if (carousel) {
      return {
        carousel,
        items:       carousel.querySelectorAll('[class$="item"]'),
        thumbsOuter: carousel.querySelector('[class$="thumbs-outer"]'),
        thumbsInner: carousel.querySelector('[class$="thumbs-inner"]'),
        thumbs:      carousel.querySelector('[class$="thumbs"]')
      };
    }
  }

  initLory() {
    if (!this.loryInstance) {
      this.setupLoryEvents();
      this.loryInstance = lory(this.DOM.carousel, {
        infinite: 1,
        rewindOnResize: false,
        slideSpeed: SLIDE_SPEED,
        classNameFrame:          'c-thumbnail-carousel__frame',
        classNamePrevCtrl:       'c-thumbnail-carousel__prev',
        classNameNextCtrl:       'c-thumbnail-carousel__next',
        classNameSlideContainer: 'c-thumbnail-carousel__container',
      });
      this.normalizeThumbContainerSize();
    }
  }

  setupLoryEvents() {
    this.DOM.carousel.addEventListener('before.lory.init', this.beforeLoryInit.bind(this));
    this.DOM.carousel.addEventListener('after.lory.init', this.afterLoryInit.bind(this));
    this.DOM.carousel.addEventListener('after.lory.slide', this.afterLorySlide.bind(this));
    this.DOM.carousel.addEventListener('on.lory.resize', () => {
      this.debounce(this.normalizeThumbContainerSize.bind(this));
    });
  }

  normalizeThumbContainerSize() {
    const thumbSize = this.DOM.thumbs.firstChild.offsetWidth;
    const outerSize = this.DOM.thumbsOuter.offsetWidth;
    const newSize = outerSize - (outerSize % thumbSize);
    this.DOM.thumbsInner.style.width = newSize+'px';
  }

  debounce(callback) {
    const DEBOUNCE_TIME = 200;
    clearTimeout(this.toHandler);
    this.toHandler = setTimeout(callback, DEBOUNCE_TIME);
  }

  beforeLoryInit() {
    const thumbs = this.DOM.thumbs;
    thumbs.innerHTML = '';
    toArray(this.DOM.items).forEach((item, i) => {
      thumbs.appendChild(this.buildThumb.call(this, item, i));
    });
    thumbs.firstChild.classList.add(ACT_CLASSNAME);
  }

  afterLoryInit() {
    document.addEventListener('keyup', (ev) => {
      if (ev.keyCode === KEYCODE_LEFT) {
        this.loryInstance.prev();
      }

      if (ev.keyCode === KEYCODE_RIGHT) {
        this.loryInstance.next();
      }
    });
  }

  buildThumb(item, i) {
    const img = item.querySelector('img').cloneNode();
    const thumb = document.createElement('li');
    img.classList = THUMB_CLASSNAME + '-img';
    thumb.appendChild(img);
    thumb.className = THUMB_CLASSNAME;
    thumb.addEventListener('click', () => {
      this.loryInstance.slideTo(i);
    });
    return thumb;
  }

  afterLorySlide(ev) {
    toArray(this.DOM.thumbs.childNodes).forEach((el, i) => {
      const action = (i === ev.detail.currentSlide-1) ? 'add' : 'remove';
      el.classList[action](ACT_CLASSNAME);
    });
    carousel.centerOn(this.DOM.thumbs, ev.detail.currentSlide-1);
  }
}

export default ThumbnailCarousel;
