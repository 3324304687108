export const breakpoints = {
  small: 375,
  medium: 768,
  large: 1024,
  full: 1600,
};

export function areNodesPresent(nodelist) {
  if (nodelist) {
    const requiredNodes = Object.keys(nodelist);
    const availableNodes = requiredNodes.filter(key => {
      return (nodelist[key] === null || nodelist[key].length === 0) ? false : true;
    });
    if (availableNodes.length && availableNodes.length === requiredNodes.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function toArray(obj) {
  return Array.prototype.slice.call(obj);
}

export const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
