import { toArray, areNodesPresent } from './utils';

class ScrollManager {

  constructor() {
    const dom = this.getDOM();
    if (areNodesPresent(dom)) {
      this.DOM = dom;
      this.duration = 600;
      this.setupEvents();
    }
  }

  getDOM() {
    return {
      triggers: document.querySelectorAll('.js-scroll')
    };
  }

  setupEvents() {
    toArray(this.DOM.triggers).forEach(trigger => {
      trigger.addEventListener('click', this.scroll.bind(this));
    });
  }

  getCurrentScroll() {
    let yScroll;

    if (window.pageYOffset) {
      yScroll = window.pageYOffset;
    } else if (document.documentElement && document.documentElement.scrollTop) {
      yScroll = document.documentElement.scrollTop;
    } else if (document.body) {
      yScroll = document.body.scrollTop;
    }
    return yScroll;
  }

  getPageScroll() {
    const windowHeight = document.documentElement.clientHeight;
    const scrollableHeight = document.documentElement.scrollHeight;
    const maxScrollTop = Math.max(0, scrollableHeight - windowHeight);
    return Math.min(windowHeight, maxScrollTop);
  }

  scroll() {
    const body = document.body;
    const targetOffset = this.getPageScroll();
    const currentPosition = this.getCurrentScroll();
    body.classList.add('in-transition');
    body.style.transform = "translate(0, -" + (targetOffset - currentPosition) + "px)";

    window.setTimeout(() => {
      body.classList.remove('in-transition');
      body.style.cssText = '';
      window.scrollTo(0, targetOffset);
    }, this.duration);
  }
}

export default ScrollManager;
