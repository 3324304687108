import { toArray } from './utils';

class ContentEffects {
  constructor() {
    this.elements = toArray(document.querySelectorAll('.js-slidein'));
    this.setupEffects();
  }

  setupEffects() {
    let observerOptions = {};
    this.observer =
      new IntersectionObserver(this.observerCallback.bind(this), observerOptions);

    this.elements.forEach((el) => this.observer.observe(el));
  }

  observerCallback(entries) {
    entries.forEach((entry) => {
      if((typeof entry.isIntersecting != 'boolean' && entry.intersectionRatio == 0) || entry.isIntersecting === false) {
        return;
      }
      entry.target.classList.add('animate');
      this.observer.unobserve(entry.target);
    })
  }
}

export default ContentEffects;
