import Masonry from 'masonry-layout';
import { areNodesPresent, toArray } from './utils';

class TileManager {
  constructor() {
    const dom = this.getDOM();
    if (areNodesPresent(dom)) {
      this.DOM = dom;
      this.setupSelectEvents();
      this.setupButtonsEvents();
      this.initMasonry();
    }
  }

  getDOM() {
    return {
      select: document.querySelector('.js-filter-select'),
      buttons: document.querySelectorAll('.js-filter-button'),
      tiles: document.querySelectorAll('.c-tile[data-type]'),
      grid: document.querySelector('.js-grid'),
      gutterSizer: document.querySelector('.c-masonry-grid__gutter-sizer'),
      columnSizer: document.querySelector('.c-masonry-grid__column-sizer'),
    };
  }

  setupSelectEvents() {
    this.DOM.select.addEventListener('change', (ev) => {
      this.filterTiles(ev.target.value);
    });
  }

  setupButtonsEvents() {
    toArray(this.DOM.buttons).forEach((button) => {
      button.addEventListener('click', (ev) => {
        const classlist = toArray(ev.currentTarget.classList);
        const alreadyActive = classlist.indexOf('active') > -1;
        const filter = alreadyActive ? '' : ev.target.dataset.filter;
        this.filterTiles(filter);
        this.DOM.select.value = filter;
      });
    });
  }

  updateActiveClass(value) {
    toArray(this.DOM.buttons).forEach((button) => {
      const action = (value && value === button.dataset.filter) ? 'add' : 'remove';
      button.classList[action]('active');
    });
  }

  filterTiles(value) {
    toArray(this.DOM.tiles).forEach(tile => {
      const types = tile.dataset.type.split(' ');
      const action = (value && types.indexOf(value) === -1) ? 'add' : 'remove';
      tile.classList[action]('u-hide--real');
    });
    this.updateActiveClass(value);
    this.masonry.layout();
  }

  initMasonry() {
    this.masonry = new Masonry(this.DOM.grid, {
      gutter: this.DOM.gutterSizer.offsetWidth,
      columnWidth: this.DOM.columnSizer,
    });
  }
}

export default TileManager;
