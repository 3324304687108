/*
  Project: Easton Porter
  Author: Xfive
 */

import 'intersection-observer';

import SocialSnippets from './SocialSnippets';
import DefaultCarousel from './DefaultCarousel';
import CoversCarousel from './CoversCarousel';
import PhotoCarousel from './PhotoCarousel';
import ThumbnailCarousel from './ThumbnailCarousel';
import Navigation from './Navigation';
import TileManager from './TileManager';
import ScrollManager from './ScrollManager';
import TMFounders from './TMFounders';
import AboutElements from './AboutElements.js';
import ContentEffects from './ContentEffects.js';
import Newsletter from './Newsletter.js';
import FilterBar from './FilterBar.js';
import PressLoader from './PressLoader.js';

new SocialSnippets();
new DefaultCarousel();
new CoversCarousel();
new PhotoCarousel();
new ThumbnailCarousel();
new Navigation();
new TileManager();
new ScrollManager();
new TMFounders();
new AboutElements();
new ContentEffects();
new Newsletter();
new FilterBar();
new PressLoader();
