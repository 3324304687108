import { toArray } from './utils';

class AboutElements {
  constructor() {
    let elements = this.elements = toArray(document.querySelectorAll('.js-aboutrow'));
    if(!this.elements.length) {
      return;
    }

    this.buttons = elements.map((el) => el.querySelector('.js-aboutrow-btn'));
    this.toggleElements = elements.map((el) =>
      toArray(el.querySelectorAll('.js-aboutrow-toggle')));
    this.setupListeners();
  }

  setupListeners() {
    this.buttons.forEach((btn, index) => {
      btn.addEventListener('click', this.toggle.bind(this, btn, index));
    });
  }

  toggle(button, index) {
    let elementsToToggle = this.toggleElements[index];
    elementsToToggle.forEach((el) => el.classList.toggle('is-visible'));

    button.classList.toggle('is-open');
  }
}

export default AboutElements;
