import { areNodesPresent } from './utils.js';
import { carousel } from './carousel-helpers.js';

class PhotoCarousel {
  constructor() {
    const nodelist = this.getNodes();
    if (areNodesPresent(nodelist)) {
      this.DOM = nodelist;
      this.initCarousel();
    }
  }

  getNodes() {
    const carousel = document.querySelector('.js-photo-carousel');
    if (carousel) {
      return {
        carousel,
        container: carousel.querySelector('.c-carousel__container'),
        items: carousel.querySelectorAll('.c-carousel__item'),
        prev: carousel.querySelector('.c-carousel__prev'),
        next: carousel.querySelector('.c-carousel__next'),
      };
    }
  }

  initCarousel() {
    if (!this.instance) {
      this.instance = true;
      this.setupListeners();
    }
  }

  setupListeners() {
    this.DOM.prev.addEventListener('click', () => {
      const currentItem = carousel.getItemInCenter(this.DOM.container);
      carousel.centerOn(this.DOM.container, currentItem-1);
    });

    this.DOM.next.addEventListener('click', () => {
      const currentItem = carousel.getItemInCenter(this.DOM.container);
      carousel.centerOn(this.DOM.container, currentItem+1);
    });
  }
}

export default PhotoCarousel;
