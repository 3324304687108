import { areNodesPresent, emailRegex } from './utils.js';

class Newsletter {
  constructor() {
    const nodelist = this.getNodes();
    if (areNodesPresent(nodelist)) {
      this.DOM = nodelist;
      this.bindNewsletterEvents();
    }
  }

  getNodes() {
    const input = document.querySelector('.js-newsletter-input');
    const submit = document.querySelector('.js-newsletter-submit');
    return {
      input,
      submit
    };
  }

  bindNewsletterEvents() {
    this.DOM.input.addEventListener('input', (ev) => {
      const email = ev.target.value;
      this.DOM.submit.disabled = emailRegex.test(email) ? false : true;
    });
    this.DOM.input.addEventListener('keyup', (ev) => {
      ev.stopPropagation();
    });
  }
}

export default Newsletter;
