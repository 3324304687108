import { toArray } from './utils';

class TMFounders {
  constructor() {
    this.body = document.body;
    this.tmfounders = document.querySelector('.js-tmfounders');
    if(!this.tmfounders) {
      return;
    }

    this.personButtons = toArray(this.tmfounders.querySelectorAll('.js-tmfounders-btn'));
    this.closeButtons = toArray(this.tmfounders.querySelectorAll('.js-tmfounders-close'));
    this.middleColumn = this.tmfounders.querySelector('.js-tmfounders-middle');
    this.personElements = {
      she: toArray(this.tmfounders.querySelectorAll('.js-tmfounders-she')),
      he: toArray(this.tmfounders.querySelectorAll('.js-tmfounders-he')),
    };
    this.personElementMiddle = {
      she: this.middleColumn.querySelector('.js-tmfounders-she'),
      he: this.middleColumn.querySelector('.js-tmfounders-he'),
    };
    this.personElementClose = {
      she: this.personElementMiddle.she.querySelector('.js-tmfounders-close'),
      he: this.personElementMiddle.he.querySelector('.js-tmfounders-close'),
    };
    this.bpMedium = window.matchMedia('(min-width: 768px)');
    this.currentPerson = null;
    this.bodyScroll = null;
    this.setupListeners();
    this.setupAccessibility();
  }

  setupListeners() {
    this.personButtons.forEach((button) => {
      button.addEventListener('click', this.togglePerson.bind(this, button.dataset.person));
    })
    this.closeButtons.forEach((button) => {
      button.addEventListener('click', this.close.bind(this));
    })
    this.bpMedium.addListener(this.breakpointChange.bind(this));
  }

  setupAccessibility() {
    window.addEventListener('keydown', (e) => {
      if(this.currentPerson !== null && e.keyCode == '27') {
        this.close();
      }
    });
  }

  togglePerson(person) {
    if(this.currentPerson && person != this.currentPerson) {
      this.togglePerson(this.currentPerson);
    }

    if(!this.personElements[person]) {
      return;
    }

    this.personElements[person].forEach((el) => el.classList.toggle('is-visible'));
    this.middleColumn.classList.toggle('has-overlay');

    if(!this.bpMedium.matches) {
      if(this.bodyScroll === null) {
        this.bodyScroll = window.pageYOffset;
        this.body.classList.toggle('u-fixed');
      } else {
        this.body.classList.toggle('u-fixed');
        window.scrollTo(0, this.bodyScroll);
        this.bodyScroll = null;
      }
    }

    this.currentPerson = (this.currentPerson === null ? person : null);
  }

  close() {
    if(this.currentPerson === null) {
      return;
    }
    this.togglePerson(this.currentPerson);
  }

  breakpointChange() {
    if(this.currentPerson) {
      // if person is selected
      if(this.bpMedium.matches) {
        // and switching from small to medium
        this.body.classList.remove('u-fixed');
        this.bodyScroll = null;
      } else {
        this.bodyScroll = window.pageYOffset;
        this.body.classList.add('u-fixed');
      }
    }
  }
}

export default TMFounders;
