import { lory } from 'lory.js';
import { breakpoints } from './utils';
import { beforeLoryInit, afterLorySlide } from './SocialSnippetsEvents';

class SocialSnippets {
  constructor() {
    this.node = document.querySelector('.js-social-snippets');
    this.loryInstance = null;
    this.setupListeners();
  }

  setupLoryEvents() {
    this.node.addEventListener('before.lory.init', beforeLoryInit);
    this.node.addEventListener('after.lory.slide', afterLorySlide);
  }

  initLory() {
    if (this.node && !this.loryInstance) {
      this.setupLoryEvents();
      this.loryInstance = lory(this.node, {
        infinite: false,
        rewindOnResize: false,
        classNameFrame: 'o-lory__frame',
        classNameSlideContainer: 'o-lory__container',
      });
    }
  }

  destroyLory() {
    if (this.loryInstance) {
      this.loryInstance.destroy();
      this.loryInstance = null;
      this.node.querySelector('.o-lory__container').removeAttribute('style'); // fix lory bug
      this.node.removeEventListener('before.lory.init', beforeLoryInit);
      this.node.removeEventListener('after.lory.slide', afterLorySlide);
    }
  }

  setupListeners() {
    this.resizeFn();
    window.addEventListener('resize', this.resizeFn.bind(this));
  }

  resizeFn() {
    if (window.innerWidth !== this.lastKnownWidth) {
      window.innerWidth >= breakpoints.medium
      ? this.destroyLory()
      : this.initLory();
      this.lastKnownWidth = window.innerWidth;
    }
  }
}

export default SocialSnippets;
