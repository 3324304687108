import { lory } from 'lory.js';
import { toArray, areNodesPresent } from './utils.js';

const DEFAULT_ROTATION_INTERVAL = 3000;
const SLIDE_SPEED = 700;

class DefaultCarousel {
  constructor() {
    const carousels = document.querySelectorAll('.js-default-carousel');
    toArray(carousels).forEach((carousel) => {
      const nodelist = this.getNodes(carousel);
      if (areNodesPresent(nodelist)) {
        this.initLory({
          DOM: nodelist
        });
      }

    });
  }

  getNodes(carousel) {
    return {
      carousel,
      slides: carousel.querySelectorAll('.o-lory__slide'),
    };
  }

  initLory(olory) {
    this.setupLoryEvents(olory);
    olory.instance = lory(olory.DOM.carousel, {
      infinite: 1,
      rewindOnResize: false,
      slideSpeed: SLIDE_SPEED,
      classNameFrame: 'o-lory__frame',
      classNameSlideContainer: 'o-lory__container',
      classNamePrevCtrl: 'o-lory__prev',
      classNameNextCtrl: 'o-lory__next',
    });
  }

  setupLoryEvents(olory) {
    olory.DOM.carousel.addEventListener('after.lory.init', () => {
      this.addNavToDom(olory.DOM);
      this.bindNavCallbacks(olory);
      this.autorotate(olory);
    });
    olory.DOM.carousel.addEventListener('on.lory.touchstart', this.cancelAutorotation.bind(olory));
  }

  addNavToDom(dom) {
    dom.prev = dom.carousel.querySelector('.o-lory__prev');
    dom.next = dom.carousel.querySelector('.o-lory__next');
  }

  goToNext() {
    this.instance.next();
  }

  autorotate(olory) {
    const dataRotationInterval = olory.DOM.carousel.getAttribute('data-rotation-interval');
    const ROTATION_INTERVAL = dataRotationInterval ? Number(dataRotationInterval) : DEFAULT_ROTATION_INTERVAL;
    olory.intervalHandler = setInterval(this.goToNext.bind(olory), ROTATION_INTERVAL);
  }

  cancelAutorotation() {
    clearInterval(this.intervalHandler);
  }

  bindNavCallbacks(olory) {
    olory.DOM.prev.addEventListener('click', this.cancelAutorotation.bind(olory));
    olory.DOM.next.addEventListener('click', this.cancelAutorotation.bind(olory));
  }
}

export default DefaultCarousel;
