import { lory } from 'lory.js';

const SLIDE_SPEED = 500;

class CoversCarousel {
  constructor() {
    this.node = document.querySelector('.js-covers-carousel');
    this.loryInstance = null;
    this.initLory();
  }

  initLory() {
    if (this.node && !this.loryInstance) {
      this.loryInstance = lory(this.node, {
        infinite: false,
        slideSpeed: SLIDE_SPEED,
        classNameFrame: 'o-lory__frame',
        classNameSlideContainer: 'o-lory__container',
        classNamePrevCtrl: 'o-lory__prev',
        classNameNextCtrl: 'o-lory__next',
      });
      this.setupListeners();
    }
  }

  setupListeners() {
    this.resizeFn();
    window.addEventListener('resize', this.resizeFn.bind(this));
  }

  resizeFn() {
    const SLIDE_SELECTOR = '.o-lory__slide';
    const slide = this.node.querySelector(SLIDE_SELECTOR);
    const maxFit = Math.floor(this.node.parentNode.offsetWidth / slide.offsetWidth);
    const newContainerSize = maxFit * slide.offsetWidth;
    this.node.style.width = newContainerSize+'px';
    this.node.style.margin = '0 auto';
  }
}

export default CoversCarousel;
