import { toArray } from './utils';

const ITEM_SELECTOR = '.c-social-snippets__item';
const DOTS_SELECTOR = '.o-lory__dots';
const DOT_CLASSNAME = 'o-lory__dots-item';
const ACT_CLASSNAME = 'active';

function beforeLoryInit() {
  const items           = this.querySelectorAll(ITEM_SELECTOR);
  const dotContainer    = this.querySelector(DOTS_SELECTOR);

  dotContainer.innerHTML = '';
  toArray(items).forEach(() => {
    const dot = document.createElement('li');
    dot.className = DOT_CLASSNAME;
    dotContainer.appendChild(dot);
  });
  dotContainer.firstChild.classList.add(ACT_CLASSNAME);
}

function afterLorySlide(ev) {
  const dotContainer = this.querySelector(DOTS_SELECTOR);
  toArray(dotContainer.childNodes).forEach((el, i) => {
    const action = (i === ev.detail.currentSlide) ? 'add' : 'remove';
    el.classList[action](ACT_CLASSNAME);
  });
}

export { beforeLoryInit, afterLorySlide };
