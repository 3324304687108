import { toArray } from './utils';

class Navigation {
  constructor() {
    this.body = document.body;
    this.nav = document.querySelector('.js-nav');
    this.header = document.querySelector('.js-header');
    this.triggers = document.querySelectorAll('.js-nav-trigger');
    this.setupListeners();
  }

  setupListeners() {
    toArray(this.triggers).forEach(el => {
      el.addEventListener('click', this.toggleNav.bind(this));
    });
  }

  lockScrolling(e) {
    e.preventDefault();
  }

  toggleNav() {
    const OPEN_CLASS = 'is-open';
    const OPEN_NAV_CLASS = 'is-nav-open';
    const action = this.nav.classList.contains(OPEN_CLASS) ? 'remove' : 'add';
    this.body[action+'EventListener']('touchmove', this.lockScrolling);
    this.nav.classList[action](OPEN_CLASS);
    this.body.classList[action](OPEN_NAV_CLASS);
    this.header.classList[action](OPEN_NAV_CLASS);
    toArray(this.triggers).forEach(el => {
      el.classList[action](OPEN_NAV_CLASS);
    });
  }
}

export default Navigation;
