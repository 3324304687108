import { toArray } from './utils';

class FilterBar {
  constructor() {
    this.inputs = toArray(document.querySelectorAll('.js-filterbar-input'));
    this.forms = toArray(document.querySelectorAll('.js-filterbar-form'));
    this.setupListeners();
  }

  setupListeners() {
    this.inputs.forEach(
      el => el.addEventListener('change', this.changeEvent.bind(this, el))
    );
  }

  changeEvent(input) {
    for(let i = 0; i < this.forms.length; i++) {
      let form = this.forms[i];
      if(form.contains(input)) {
        if(form.__EPPressLoad) {
          form.__EPPressLoad();
        } else {
          form.submit();
        }
        return;
      }
    }
  }
}

export default FilterBar;
