class PressLoader {
  constructor() {
    this.currentPage = 1;
    this.loading = false;
    this.button = document.querySelector('.js-pressloader-button');
    if(!this.button || !window.ajaxurl) {
      return;
    }
    this.filterForm = document.querySelector('.js-filterbar-form');
    this.filterForm.__EPPressLoad = this.reloadResults.bind(this);
    this.grid = document.querySelector('.js-pressloader-grid');
    this.setupListeners();
  }

  setupListeners() {
    this.button.addEventListener('click', this.buttonClicked.bind(this), true);
    this.filterForm.addEventListener('submit', (e) => {
      e.preventDefault();
      this.reloadResults();
    }, true);
  }

  buttonClicked(e) {
    e.preventDefault();
    this.loadMore();
  }

  reloadResults() {
    this.currentPage = 0;
    this.loadMore();
  }

  loadMore() {
    if(this.loading) {
      return;
    }
    this.loading = true;
    var filterForm = this.filterForm;

    this.request({
      action: 'get_press',
      date: filterForm.elements.date.value,
      search: filterForm.elements.search.value,
      category: filterForm.elements.category.value,
      page: this.currentPage+1
    }, (err, data) => {
      this.loading = false;
      if(err) {

        return;
      }
      if(this.currentPage == 0) {
        this.grid.innerHTML = '';
      }
      this.currentPage++;
      let posts = data.posts;
      for(let i = 0; i < posts.length; i++) {
        let post = posts[i];
        let tmp = document.createElement('div');
        tmp.innerHTML = post;
        this.grid.appendChild(tmp.children[0]);
      }
      if(!data.has_more) {
        this.button.classList.add('is-hidden');
      } else {
        this.button.classList.remove('is-hidden');
      }
    });
  }

  request(data, cb) {
    var args = [];
    Object.keys(data).forEach(function(key) {
      args.push(encodeURIComponent(key)+'='+encodeURIComponent(data[key]));
    })
    var xhr = new XMLHttpRequest();
    xhr.open('POST', window.ajaxurl, true);
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.timeout = 10000;

    xhr.onload = function() {
      var data = null;
      try {
        data = JSON.parse(xhr.responseText);
      } catch(e) {}
      if(data) {
        cb(null, data);
      } else {
        cb('JSON Parsing error');
      }
    }

    xhr.ontimeout = function() {
      cb('Timeout');
    }

    xhr.onerror = function() {
      cb('Error');
    }

    xhr.send(args.join('&'));
  }
}

export default PressLoader;
