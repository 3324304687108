function translateX(value) {
  return 'translateX(' + value + 'px)';
}

function getShift(node) {
  const transform = node.style.transform;
  const result = transform.match(/translateX\((\-?\d*\.?\d*)px\)/, 'g');
  return result ? result[1] : 0;
}

function setShift(node, value) {
  node.style.transform = translateX(value);
}

function getSizeMapping(items) {
  return Array.prototype.map.call(items, item => {
    return item.offsetWidth;
  });
}

function getCummulativeMapping(items) {
  let accum = 0;
  return getSizeMapping(items).map((size) => {
    return accum += size;
  });
}

function getCenterOfViewport(container) {
  return getViewportSize(container)/2;
}

function getViewportSize(element) {
  return element ? element.offsetWidth : window.innerWidth;
}

function normalize(container, shift) {
  const nodes = getChildren(container);
  const totalSize = getSizeMapping(nodes).reduce((accum, curr) => {
    return accum+curr;
  }, 0);
  const min = -totalSize + getViewportSize(container);
  return Math.min(0, Math.max(min, shift)); // min < shift < 0
}

function getChildren(container) {
  const allNodes = Array.prototype.slice.call(container.childNodes);
  const nodes = allNodes.filter((node) => {
    return node.nodeType === 1 ? true : false;
  });
  return nodes;
}

function centerOn(container, n) {
  const nodes = getChildren(container);
  const nthSize = getSizeMapping(nodes)[n];
  const nthCenter = getCummulativeMapping(nodes)[n] - nthSize/2;
  const shift = getCenterOfViewport(container) - nthCenter;
  const normalizedShift = normalize(container, shift);
  setShift(container, normalizedShift);
}

function getItemInCenter(container) {
  const center = getCenterOfViewport(container);
  const shift = getShift(container);
  const sizes = getSizeMapping(getChildren(container));
  let accum = 0;
  for (let i = 0; i < sizes.length; i++) {
    accum += sizes[i];
    if (center - shift < accum) {
      return i;
    }
  }
}

export const carousel = {
  centerOn,
  getItemInCenter
};
